<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="['16px', '24px']"
  >
    <c-form-label
      :font-size="['14px', '16px']"
      :color="value && value.length > 0 ? '#008C81' : '#555'"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <c-box 
      v-if="subLabel"
      as="p"
      :font-size="['14px', '16px']"
      color="#555"
      font-weight="400"
      font-family="Roboto"
      mb="8px"
    >
      {{ subLabel }}
    </c-box>
    <c-stack>
      <c-checkbox-group
        v-model="modelValue"
        variant-color="primary"
        :is-disabled="isDisabled"
        size="lg"
        :font-size="['14px', '16px']"
        font-weight="400"
        color="#111"
        spacing="2"
      >
        <template v-for="(option, index) in options">
          <c-checkbox  
            :key="index" 
            :value="option.value"
            :is-disabled="isDisabled || (isIncludesValue(disabledOption) && disabledOption != option.value)"
            :color="isIncludesValue(option.value) ? '#008C81' : '#555'"
            :font-weight="isIncludesValue(option.value) ? '500' : '400'"
          >
            {{ option.label }}
          </c-checkbox>
          <c-form-control
            v-if="isOther && (option.value == otherOption) && isIncludesValue(otherOption) && !isIncludesValue(disabledOption)"
            :key="`custom-type-${index}`"
            :is-invalid="otherValue ? false : true"
            ml="28px"
          >
            <c-input
              v-model="otherValue"
              type="text"
              :height="['48px', '62px']"
              :font-size="['14px', '18px']"
              padding-right="70px"
              :color="otherValue ? '#008C81' : '#555'"
              :font-weight="otherValue ? '500' : '400'"
              placeholder="Masukkan masalah kesehatan lainnya"
              mb="8px"
            />
          </c-form-control>
        </template>
      </c-checkbox-group>
    </c-stack>
    <c-form-helper-text
      v-if="isHelper"
      font-size="12px"
    >
      {{ helperText }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>

export default {
  name: 'FormCheckbox',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    disabledOption: {
      type: String,
      default: '',
    },
    isOther: {
      type: Boolean,
      default: false,
    },
    other: {
      type: String,
      default: '',
    },
    otherOption: {
      type: String,
      default: '',
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        if (this.disabledOption && val.includes(this.disabledOption)) {
          const data = [this.disabledOption]
          this.$emit('input', data)
        } else {
          this.$emit('input', val)
        }
      },
    },
    otherValue: {
      get() {
        return this.other
      },
      set(val) {
        this.$emit('updateOther', val)
      },
    },
  },
  methods: {
    isIncludesValue(value) {
      return this.value ? this.value.includes(value) : false
    },
  }, 
}
</script>
