var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": true,
      "is-invalid": _vm.isInvalid,
      "mb": ['16px', '24px']
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.modelValue.length > 0 ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-box', {
    attrs: {
      "as": "p",
      "font-size": ['14px', '16px'],
      "color": "#555",
      "font-weight": "400",
      "font-family": "Roboto",
      "mb": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.subLabel) + " ")]), _c('c-stack', [_c('c-checkbox-group', {
    attrs: {
      "variant-color": "primary",
      "is-disabled": _vm.isDisabled,
      "size": "lg",
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "#111",
      "spacing": "2",
      "required": ""
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, [_vm._l(_vm.options, function (item) {
    return [_c('c-checkbox', {
      key: item.value,
      attrs: {
        "value": item.value,
        "is-disabled": _vm.modelValue.includes('60') && item.value !== '60',
        "color": _vm.isIncludesValue(item.value) ? '#008C81' : '#555',
        "font-weight": _vm.isIncludesValue(item.value) ? '500' : '400'
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), item.value == '68' && _vm.isIncludesValue('68') ? _c('c-form-control', {
      key: "custom-type-".concat(item.value),
      attrs: {
        "is-invalid": _vm.checkInvalidChekbox(item.value),
        "ml": "28px"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "height": ['48px', '62px'],
        "font-size": ['14px', '18px'],
        "padding-right": "70px",
        "color": _vm.customSportValue ? '#008C81' : '#555',
        "font-weight": _vm.customSportValue ? '500' : '400',
        "placeholder": "Masukkan jenis olahraga lainnya",
        "mb": "8px"
      },
      model: {
        value: _vm.customSportValue,
        callback: function callback($$v) {
          _vm.customSportValue = $$v;
        },
        expression: "customSportValue"
      }
    })], 1) : _vm._e(), _vm.modelValue.includes(item.value) && item.value !== '60' ? _c('c-form-control', {
      key: "duration-".concat(item.value),
      attrs: {
        "is-invalid": _vm.checkInvalidChekbox(item.value),
        "ml": "28px"
      }
    }, [_c('c-select', {
      attrs: {
        "height": ['48px', '62px'],
        "font-size": ['14px', '18px'],
        "placeholder": "Durasi",
        "color": _vm.durationsValue[item.value] ? '#008C81' : '#555',
        "font-weight": _vm.durationsValue[item.value] ? '500' : '400',
        "required": ""
      },
      model: {
        value: _vm.durationsValue[item.value],
        callback: function callback($$v) {
          _vm.$set(_vm.durationsValue, item.value, $$v);
        },
        expression: "durationsValue[item.value]"
      }
    }, _vm._l(_vm.optionsDurations, function (option) {
      return _c('option', {
        key: option.value,
        domProps: {
          "value": option.value
        }
      }, [_vm._v(" " + _vm._s(option.label) + " ")]);
    }), 0)], 1) : _vm._e()];
  })], 2), !_vm.v.sportType.required ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px",
      "color": "red.500"
    }
  }, [_vm._v(" Jenis olahraga lainnya harus diisi ")]) : _vm._e(), _vm._l(_vm.v.sportType.$each.$iter, function (value_, key) {
    return [!value_.duration.required ? _c('c-form-helper-text', {
      key: "validate-duration-".concat(key),
      attrs: {
        "font-size": "12px",
        "color": "red.500"
      }
    }, [_vm._v(" Durasi dari olahraga " + _vm._s(_vm.sportName(value_.$model.type)) + " harus diisi ")]) : _vm._e(), !value_.customType.required ? _c('c-form-helper-text', {
      key: "validate-type-".concat(key),
      attrs: {
        "font-size": "12px",
        "color": "red.500"
      }
    }, [_vm._v(" Jenis olahraga harus diisi ")]) : _vm._e()];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }