<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="true"
    :is-invalid="isInvalid"
    :mb="['16px', '24px']"
  >
    <c-form-label
      :font-size="['14px', '16px']"
      :color="modelValue.length > 0 ? '#008C81' : '#555'"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
    </c-form-label>
    <c-box
      as="p"
      :font-size="['14px', '16px']"
      color="#555"
      font-weight="400"
      font-family="Roboto"
      mb="8px"
    >
      {{ subLabel }}
    </c-box>
    <c-stack>
      <c-checkbox-group
        v-model="modelValue"
        variant-color="primary"
        :is-disabled="isDisabled"
        size="lg"
        :font-size="['14px', '16px']"
        font-weight="400"
        color="#111"
        spacing="2"
        required
      >
        <template v-for="item in options">
          <c-checkbox
            :key="item.value"
            :value="item.value"
            :is-disabled="modelValue.includes('60') && item.value !== '60'"
            :color="isIncludesValue(item.value) ? '#008C81' : '#555'"
            :font-weight="isIncludesValue(item.value) ? '500' : '400'"
          >
            {{ item.label }}
          </c-checkbox>
          <c-form-control
            v-if="item.value == '68' && isIncludesValue('68')"
            :key="`custom-type-${item.value}`"
            :is-invalid="checkInvalidChekbox(item.value)"
            ml="28px"
          >
            <c-input
              v-model="customSportValue"
              type="text"
              :height="['48px', '62px']"
              :font-size="['14px', '18px']"
              padding-right="70px"
              :color="customSportValue ? '#008C81' : '#555'"
              :font-weight="customSportValue ? '500' : '400'"
              placeholder="Masukkan jenis olahraga lainnya"
              mb="8px"
            />
          </c-form-control>
          <c-form-control
            v-if="modelValue.includes(item.value) && item.value !== '60'"
            :key="`duration-${item.value}`"
            :is-invalid="checkInvalidChekbox(item.value)"
            ml="28px"
          >
            <c-select
              v-model="durationsValue[item.value]"
              :height="['48px', '62px']"
              :font-size="['14px', '18px']"
              placeholder="Durasi"
              :color="durationsValue[item.value] ? '#008C81' : '#555'"
              :font-weight="durationsValue[item.value] ? '500' : '400'"
              required
            >
              <option
                v-for="option in optionsDurations"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </c-select>
          </c-form-control>
        </template>
      </c-checkbox-group>
      <c-form-helper-text
        v-if="!v.sportType.required"
        font-size="12px"
        color="red.500"
      >
        Jenis olahraga lainnya harus diisi
      </c-form-helper-text>
      <template v-for="(value_, key) in v.sportType.$each.$iter">
        <c-form-helper-text
          v-if="!value_.duration.required"
          :key="`validate-duration-${key}`"
          font-size="12px"
          color="red.500"
        >
          Durasi dari olahraga {{ sportName(value_.$model.type) }} harus diisi
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!value_.customType.required"
          :key="`validate-type-${key}`"
          font-size="12px"
          color="red.500"
        >
          Jenis olahraga harus diisi
        </c-form-helper-text>
      </template>
    </c-stack>
  </c-form-control>
</template>

<script>
export default {
  name: 'FormCheckboxSport',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      required: true,
    },
    subLabel: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabledOption: {
      type: String,
      default: '',
    },
    isOther: {
      type: Boolean,
      default: false,
    },
    optionsDurations: {
      type: Array,
      default: () => [],
    },
    durations: {
      type: Object,
      default: () => ({}),
    },
    customSport: {
      type: String,
      default: '',
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        if (this.disabledOption && val.includes(this.disabledOption)) {
          const data = [this.disabledOption]
          this.$emit('input', data)
        } else {
          this.$emit('input', val)
        }
      },
    },
    durationsValue: {
      get() {
        return this.durations
      },
      set(val) {
        this.$emit('updateDurations', val)
      }, 
    },
    customSportValue: {
      get() {
        return this.customSport
      },
      set(val) {
        this.$emit('updateCustomSport', val)
      },
    },
  },
  methods: {
    isIncludesValue(value) {
      return this.value ? this.value.includes(value) : false
    },
    checkInvalidChekbox(value) {
      const it = Object.values(this.v.sportType.$each.$iter).find((it) => it.$model.type == value)
      
      if (it) {
        return it.$invalid
      }
      return false
    },
    sportName(value) {
      if (this.options.length > 0) {
        return this.options.find((it) => it.value == value)?.label
      }
      return value
    },
  },
}
</script>
