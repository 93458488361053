<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="['16px', '24px']"
  >
    <c-form-label
      :font-size="['14px', '16px']"
      :color="value ? '#008C81' : '#555'"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <c-stack>
      <c-radio-group
        v-model="modelValue"
        variant-color="primary"
        :is-disabled="isDisabled"
        size="lg"
        :font-size="['14px', '16px']"
        spacing="2"
      >
        <c-radio 
          v-for="(option, index) in options" 
          :key="index" 
          :value="option.value"
          :is-disabled="isDisabled"
          :color="value == option.value ? '#008C81' : '#555'"
          :font-weight="value == option.value ? '500' : '400'"
        >
          {{ option.label }}
        </c-radio>
      </c-radio-group>
    </c-stack>
    <c-form-helper-text
      v-if="isHelper"
      font-size="12px"
    >
      {{ helperText }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>

export default {
  name: 'FormRadio',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, null, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  }, 
}
</script>
