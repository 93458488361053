var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "kuisioner",
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Formulir Gizi',
        href: '/',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "bg": "#FFF",
      "p": ['1rem', '0px']
    }
  }, [_c('Steps', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "current": 4,
      "total": 6,
      "mt": "30px"
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px",
      "mx": "auto",
      "margin-top": "50px"
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "text-transform": "uppercase",
      "font-weight": "500",
      "font-size": ['16px', '18px'],
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Formulir Gizi (4/6) ")]), _c('c-heading', {
    attrs: {
      "font-weight": "700",
      "font-size": ['18px', '28px'],
      "line-height": ['27px', '42px'],
      "color": "black.900"
    }
  }, [_vm._v(" Data Aktivitas Harian ")])], 1), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input[type=radio]:checked': {
          bg: 'primary.400',
          borderColor: 'primary.400'
        },
        'input[type=checkbox]:checked': {
          bg: 'primary.400',
          borderColor: 'primary.400'
        },
        div: {
          fontSize: ['14px', '18px']
        }
      },
      expression: "{\n            'input[type=radio]:checked': {\n              bg: 'primary.400',\n              borderColor: 'primary.400',\n            },\n            'input[type=checkbox]:checked': {\n              bg: 'primary.400',\n              borderColor: 'primary.400',\n            },\n            div: {\n              fontSize: ['14px', '18px'],\n            },\n          }"
    }],
    attrs: {
      "margin-top": "24px"
    }
  }, [_c('FormCheckboxSport', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.sportType.$invalid,
      "v": _vm.$v,
      "label": "Selama 1 minggu terakhir, pilih semua jenis aktivitas atau olahraga yang sudah kamu lakukan dan durasinya",
      "sub-label": "Jenis olahraga yang dilakukan",
      "options": _vm.SPORT_TYPE,
      "disabled-option": "60",
      "is-other": true,
      "other-option": "68",
      "options-durations": _vm.SPORT_DURATION,
      "durations": _vm.durations,
      "custom-sport": _vm.customSport
    },
    on: {
      "updateDurations": _vm.updateDurations,
      "updateCustomSport": _vm.updateCustomSport
    },
    model: {
      value: _vm.sportType_,
      callback: function callback($$v) {
        _vm.sportType_ = $$v;
      },
      expression: "sportType_"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.weekdaysTypicalActivity.$invalid,
      "label": "Tipikal keaktifan kamu pada hari kerja (weekdays)",
      "placeholder": "Pilih tipikal",
      "options": _vm.WEEKDAYS_TYPICAL_ACTIVITY
    },
    model: {
      value: _vm.weekdaysTypicalActivity,
      callback: function callback($$v) {
        _vm.weekdaysTypicalActivity = $$v;
      },
      expression: "weekdaysTypicalActivity"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.weekendTypicalActivity.$invalid,
      "label": "Tipikal keaktifan kamu pada hari libur dan akhir pekan",
      "placeholder": "Pilih tipikal",
      "options": _vm.WEEKEND_TYPICAL_ACTIVITY
    },
    model: {
      value: _vm.weekendTypicalActivity,
      callback: function callback($$v) {
        _vm.weekendTypicalActivity = $$v;
      },
      expression: "weekendTypicalActivity"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.reducePhysicalActivity.$invalid,
      "label": "Apakah kamu pernah didiagnosa dokter untuk mengurangi aktifitas fisik kecuali yang direkomendasikan dokter?",
      "options": _vm.LIMITATION_ACTIVITY
    },
    model: {
      value: _vm.reducePhysicalActivity,
      callback: function callback($$v) {
        _vm.reducePhysicalActivity = $$v;
      },
      expression: "reducePhysicalActivity"
    }
  }), _c('FormCheckbox', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.physicalLimitationActivityStore.$invalid,
      "label": "Apakah kamu memiliki keterbatasan berikut untuk melakukan aktivitas fisik?",
      "options": _vm.PHYSICAL_ACTIVITY,
      "disabled-option": "85",
      "is-other": true,
      "other-option": "138",
      "other": _vm.physicalLimitationActivityOther
    },
    on: {
      "updateOther": _vm.updatePhysicalLimitationActivityOther
    },
    model: {
      value: _vm.physicalLimitationActivity_,
      callback: function callback($$v) {
        _vm.physicalLimitationActivity_ = $$v;
      },
      expression: "physicalLimitationActivity_"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.averageSleepHours.$invalid,
      "label": "Berapa jam rata-rata durasi tidur per hari",
      "placeholder": "Masukkan durasi tidur",
      "right-element": "Jam",
      "invalid-text": _vm.$v.averageSleepHours.minValue ? _vm.$v.averageSleepHours.maxValue ? '' : 'Jam tidur tidak boleh lebih dari 24 jam' : 'Jam tidur tidak boleh kurang dari 0 jam'
    },
    model: {
      value: _vm.averageSleepHours,
      callback: function callback($$v) {
        _vm.averageSleepHours = $$v;
      },
      expression: "averageSleepHours"
    }
  }), _c('c-form-control', {
    attrs: {
      "id": "bedtime",
      "is-disabled": !_vm.isEditable,
      "is-required": true,
      "is-invalid": _vm.$v.bedTime.$invalid,
      "mb": ['16px', '24px']
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jam berapa biasa kamu tidur? ")]), _c('vue-timepicker', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input.display-time': {
          p: '1rem !important',
          height: ['48px !important', '62px !important'],
          fontFamily: 'Roboto',
          fontWeight: '500',
          borderRadius: '4px',
          border: '1px solid #CBD5E0'
        }
      },
      expression: "{\n                'input.display-time': {\n                  p: '1rem !important',\n                  height: ['48px !important', '62px !important'],\n                  fontFamily: 'Roboto',\n                  fontWeight: '500',\n                  borderRadius: '4px',\n                  border: '1px solid #CBD5E0',\n                }\n              }"
    }],
    attrs: {
      "format": "HH:mm",
      "minute-interval": 1,
      "hour-label": "Jam",
      "minute-label": "Menit",
      "input-width": "100%",
      "close-on-complete": false,
      "hide-clear-button": false,
      "drop-direction": "up",
      "container-id": "bedtime",
      "disabled": !_vm.isEditable,
      "input-class": ['skip-error-style', _vm.$v.bedTime.$invalid ? 'invalid' : ''],
      "font-size": ['14px', '18px'],
      "color": _vm.bedTime ? '#008C81' : '#555'
    },
    model: {
      value: _vm.bedTime,
      callback: function callback($$v) {
        _vm.bedTime = $$v;
      },
      expression: "bedTime"
    }
  }), !_vm.$v.bedTime.required ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px",
      "color": "red.500"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e(), !_vm.$v.bedTime.format ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px",
      "color": "red.500"
    }
  }, [_vm._v(" Format waktu tidak benar (contoh: 21:30) ")]) : _vm._e()], 1), _c('c-grid', {
    attrs: {
      "margin-top": "16px",
      "template-columns": "repeat(2, 1fr)",
      "gap": "3"
    }
  }, [_c('c-button', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "color": "primary.400",
      "border-color": "primary.400",
      "border-radius": "100px",
      "variant": "outline",
      "px": "30px",
      "h": "48px",
      "is-disabled": _vm.isSubmitting || _vm.isBack
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "w": "100%",
      "background-color": "primary.400",
      "color": "white",
      "border-radius": "100px",
      "variant": "solid",
      "px": "30px",
      "is-disabled": _vm.isDisabled || _vm.isBack,
      "is-loading": _vm.isSubmitting,
      "loading-text": "Submitting",
      "h": "48px",
      "type": "submit"
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }