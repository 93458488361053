var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "mb": ['16px', '24px']
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.value && _vm.value.length > 0 ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1), _vm.subLabel ? _c('c-box', {
    attrs: {
      "as": "p",
      "font-size": ['14px', '16px'],
      "color": "#555",
      "font-weight": "400",
      "font-family": "Roboto",
      "mb": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.subLabel) + " ")]) : _vm._e(), _c('c-stack', [_c('c-checkbox-group', {
    attrs: {
      "variant-color": "primary",
      "is-disabled": _vm.isDisabled,
      "size": "lg",
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "#111",
      "spacing": "2"
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, [_vm._l(_vm.options, function (option, index) {
    return [_c('c-checkbox', {
      key: index,
      attrs: {
        "value": option.value,
        "is-disabled": _vm.isDisabled || _vm.isIncludesValue(_vm.disabledOption) && _vm.disabledOption != option.value,
        "color": _vm.isIncludesValue(option.value) ? '#008C81' : '#555',
        "font-weight": _vm.isIncludesValue(option.value) ? '500' : '400'
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]), _vm.isOther && option.value == _vm.otherOption && _vm.isIncludesValue(_vm.otherOption) && !_vm.isIncludesValue(_vm.disabledOption) ? _c('c-form-control', {
      key: "custom-type-".concat(index),
      attrs: {
        "is-invalid": _vm.otherValue ? false : true,
        "ml": "28px"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "height": ['48px', '62px'],
        "font-size": ['14px', '18px'],
        "padding-right": "70px",
        "color": _vm.otherValue ? '#008C81' : '#555',
        "font-weight": _vm.otherValue ? '500' : '400',
        "placeholder": "Masukkan masalah kesehatan lainnya",
        "mb": "8px"
      },
      model: {
        value: _vm.otherValue,
        callback: function callback($$v) {
          _vm.otherValue = $$v;
        },
        expression: "otherValue"
      }
    })], 1) : _vm._e()];
  })], 2)], 1), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.helperText) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }