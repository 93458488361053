import { render, staticRenderFns } from "./form-checkbox-sport.vue?vue&type=template&id=741781dc&"
import script from "./form-checkbox-sport.vue?vue&type=script&lang=js&"
export * from "./form-checkbox-sport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CBox: require('@chakra-ui/vue').CBox, CCheckbox: require('@chakra-ui/vue').CCheckbox, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CSelect: require('@chakra-ui/vue').CSelect, CCheckboxGroup: require('@chakra-ui/vue').CCheckboxGroup, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CStack: require('@chakra-ui/vue').CStack})
