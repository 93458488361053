var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "mb": ['16px', '24px']
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1), _c('c-stack', [_c('c-radio-group', {
    attrs: {
      "variant-color": "primary",
      "is-disabled": _vm.isDisabled,
      "size": "lg",
      "font-size": ['14px', '16px'],
      "spacing": "2"
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, _vm._l(_vm.options, function (option, index) {
    return _c('c-radio', {
      key: index,
      attrs: {
        "value": option.value,
        "is-disabled": _vm.isDisabled,
        "color": _vm.value == option.value ? '#008C81' : '#555',
        "font-weight": _vm.value == option.value ? '500' : '400'
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  }), 1)], 1), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.helperText) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }